<template>
  <div>
    <div class="all">
      <div class="my_headers">
        <myHeader :msg="title_msg" :states="1"></myHeader>
      </div>
      <div class="main_box">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getMoreDatas">
          <div v-for="(item,index ) in topicList" :key="index" class="topic_item">
            <a :href="item.url"><img class="thumb" :src="item.banner" alt=""></a>
            <div class="collect">
              {{ getNum(item.collect_num) }}
              <span class="text">收藏</span>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getVipCardCollection } from '@/api/my_user.js'
import myHeader from '@/compontens/heads'
export default {
  components: {
    myHeader
  },
  data() {
    return {
      title_msg: '收藏的专题',
      page: 1,
      timer: null,
      limit: 10,
      loading: false,
      finished: false,
      topicList: []
    }
  },
  mounted() {},
  methods: {
    getMoreDatas() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      this.page = this.getCurrentPage()
      console.log('page is ' + this.page)
      const params = {
        page: this.page,
        limit: this.limit
      }
      getVipCardCollection(params).then(res => {
        this.loading = false
        const result = res.result ? res.result : {}
        const list = result.list ? result.list : []
        this.count = result.count
        if (this.page === 1) {
          this.topicList = list
        } else {
          this.topicList = this.topicList.concat(list)
        }
        // 是否结束
        if (list.length < this.page) {
          this.finished = true
          console.log('没有更多了')
        } else {
          this.finished = false
        }
      })
    },
    getCurrentPage() {
      const count = this.topicList.length
      const limit = this.limit
      const page = Math.ceil((count / limit)) + 1 // 默认1
      return page
    },
    getNum(num) {
      if (num < 1000) {
        return num
      } else if (num >= 1000 && num < 10000) {
        return parseInt((num / 1000 * 10)) / 10 + 'k'
      } else if (num >= 10000) {
        return parseInt((num / 10000 * 10)) / 10 + 'w'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my_headers {
  border-bottom: 1px solid #e1e1e1;
}
.main_box{
  // width:100%;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  // width: 1842px;
  padding-bottom: 1px;
  .topic_item{
    width: 690px;
    height: 294px;
    margin-bottom: 40px;
    // margin-bottom: 30px;
    position:relative;
    float: left;
    .thumb{
      width: 100%;
      height: 100%;
    }
    .collect{
      position:absolute;
      bottom:0;
      right: 0;
      background: rgba(51,51,51,0.90);
      border-radius: 12px 0px 0px 0px;
      color:#fff;
      padding: 4px 20px;
      font-size: 24px;
      .text{
        margin-left: 4px;
      }
    }
  }
}
/deep/.van-list{
  margin:0 auto;
}
</style>
